<template>
  <div id="newsStoryContent">
    <p id="Text1">
      <!-- pic1CA855F3D6845CC80D1AABB68FC5A332.jpg -->
      <img class="rounded" src="@/assets/news-network/gamescom.jpg">
      <br>
      Our savvy developers have been working around the clock all summer long. And we’re
      extremely excited to proudly announce that the German version of the game will get
      its debut at GamesCom in Cologne August 18-22, 2010.
    </p>
    <p id="Text2">
      GamesCom is Europe’s biggest trade fair and event highlight for interactive games
      and entertainment. As part of the Warner Bros. booth, we will give fans the great
      opportunity to show off their imagination, and rock the demo machines on the show
      floor. And our German and English speaking experts will also be there to share the
      latest and the greatest with gamers, press, moms, stoked kids and everyone else who
      wants to know more about the adventure-filled LEGO Universe.
    </p>
    <p id="Text3">
      We will be also be lifting the veil on the story behind the Nexus Force Factions, and
      fans can look forward to discovering more in-depth details about the strong alliance
      between 4 brave faction leaders; the bold knight, genius builder, cunning ninja and
      brave pirate who all stand together in the battle to save imagination.
    </p>
    <p id="Text4">
      We’re anxiously counting the days and can’t wait for GamesCom to kick off. If you’re
      planning on going, swing by for the first ever hands-on demo in German!
    </p>
  </div>
</template>
